import React, { createContext, useContext, useState, useEffect } from 'react'
import { useCompanies } from '../Query/Dashboard/companyQuery'

const CompanyContext = createContext()

export const CompanyProvider = ({ children }) => {
  const { data: companiesData } = useCompanies()
  const [companies, setCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [isInitialized, setIsInitialized] = useState(false)

  // Load companies when data is available
  useEffect(() => {
    if (companiesData?.length) {
      setCompanies(companiesData)
    }
  }, [companiesData])

  // Initialize selected company from localStorage
  useEffect(() => {
    if (!isInitialized && companies.length > 0) {
      const savedCompany = localStorage.getItem('selectedCompany')
      
      if (savedCompany) {
        try {
          const parsedCompany = JSON.parse(savedCompany)
          // Check if the saved company exists in the current list
          const companyExists = companies.includes(parsedCompany)
          
          if (companyExists) {
            setSelectedCompany(parsedCompany)
          } else {
            // If saved company doesn't exist, use the first one
            setSelectedCompany(companies[0])
            localStorage.setItem('selectedCompany', JSON.stringify(companies[0]))
          }
        } catch (error) {
          console.error('Error parsing saved company:', error)
          setSelectedCompany(companies[0])
          localStorage.setItem('selectedCompany', JSON.stringify(companies[0]))
        }
      } else {
        // No saved company, use the first one
        setSelectedCompany(companies[0])
        localStorage.setItem('selectedCompany', JSON.stringify(companies[0]))
      }
      
      setIsInitialized(true)
    }
  }, [companies, isInitialized])

  // Update localStorage whenever selected company changes
  const handleSetSelectedCompany = (company) => {
    setSelectedCompany(company)
    localStorage.setItem('selectedCompany', JSON.stringify(company))
  }

  return (
    <CompanyContext.Provider
      value={{
        companies,
        setCompanies,
        selectedCompany,
        setSelectedCompany: handleSetSelectedCompany
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export const useCompany = () => {
  const context = useContext(CompanyContext)
  if (!context) {
    throw new Error('useCompany must be used within a CompanyProvider')
  }
  return context
} 