import React from "react"
import UserIcon from '../../assets/Images/UserIconSet.png'
import SuitcaseIcon from '../../assets/Images/SuitcaseIcon.png'
import { FaHome, FaCog, FaUser, FaSignOutAlt } from 'react-icons/fa'
import { MdNotificationsActive } from "react-icons/md";

export const EMAIL_REGX = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)
export const URL_REGX =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g

export const typeOfAccountData = [
  {
    icon: UserIcon,
    businessOption: 'Working as B2C',
    value: 'b2c',
    info: 'If you need more info, please check it out.'
  },
  {
    icon: SuitcaseIcon,
    businessOption: 'B2B Business',
    value: 'b2b',
    info: 'Create corporate account to many users..'
  }
]

export const accountData = [
  {
    icon: UserIcon,
    businessOption: 'Financial Operations and Consumer Protection',
    value: 'financial_operations_and_consumer_protection',
    info: 'Financial services regulations, Consumer protection, Lending and credit, Payment processing, Licensing and registration'
  },
  {
    icon: SuitcaseIcon,
    businessOption: 'Data and Technology Compliance',
    value: 'data_and_technology_compliance',
    info: 'Data security and privacy, Technology-specific regulations, Identity verification'
  },
  {
    icon: UserIcon,
    businessOption: 'Risk Management and International Compliance',
    value: 'risk_management_and_international_compliance',
    info: 'Anti-money laundering (AML) and Know Your Customer (KYC), Digital currency and blockchain, International regulations'
  }
]
export const countryName = ['All EU', 'Germany', 'Baltics', 'All US', 'TR']

// Google Signin Credentials
export const GOOGLE_SIGNIN_CDN = 'https://accounts.google.com/gsi/client'
// export const GOOGLE_CLIENT_ID = '5198218847-ivvs9b2eertamcffd3ju97oskmkp8hj0.apps.googleusercontent.com'
export const GOOGLE_CLIENT_ID = '741004844521-b94flm42t4kb304bhnu4hk1lonnfpqp1.apps.googleusercontent.com'

// SidebarConstants.js
export const sectionsData = [
  {
    id: 'home',
    name: 'Home',
    icon: FaHome,
    paths: ['/dashboard/home', '/dashboard/home/chatbox']
  },
  {
    id: 'settings',
    name: 'Settings',
    icon: FaCog,
    paths: ['/dashboard/settings']
  },
  {
    id: 'profile',
    name: 'Profile',
    icon: FaUser,
    paths: ['/dashboard/profile']
  },
  {
    id: 'logout',
    name: 'Logout',
    icon: FaSignOutAlt,
    paths: []
  }
]

export const notifications = [
  {
    title: 'Notification 1 Test one two one',
    timestamp: new Date(Date.now()),
    imageUrl: <MdNotificationsActive />
  },
  {
    title: 'Notification 1 Test one two one',
    timestamp: new Date(Date.now()) - 3 * 60 * 1000,
    imageUrl: <MdNotificationsActive />
  },
  {
    title: 'Notification 2 Test one two one',
    timestamp: new Date(Date.now() - 5 * 60 * 1000), // 5 minutes ago
    imageUrl: <MdNotificationsActive />
  },
  {
    title: 'Notification 3 Test one two one',
    timestamp: new Date(Date.now() - 10 * 60 * 1000), // 10 minutes ago
    imageUrl: <MdNotificationsActive />
  }
]

export const timeAgo = (date) => {
  const now = new Date()
  const diffInSeconds = Math.floor((now - date) / 1000)
  const diffInMinutes = Math.floor(diffInSeconds / 60)
  const diffInHours = Math.floor(diffInMinutes / 60)
  const diffInDays = Math.floor(diffInHours / 24)

  if (diffInDays > 0) {
    return `${diffInDays} day(s) ago`
  } else if (diffInHours > 0) {
    return `${diffInHours} hour(s) ago`
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} minute(s) ago`
  } else {
    return 'Just now'
  }
}

export const REACT_APP_ENV = process.env.REACT_APP_ENV || 'production'
export const USER_BASE_URL = process.env.REACT_APP_ENV === 'development' ? 'http://localhost:3001/api/user' : '/api/user'

export const PUBLIC_KEY =
  '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcH/vsYhMCJjRMewB42I2VKowxbxdIXYgDzx9e3Hv87teNIyISc9kVTtFLqcIIKTekGXh78CR9FkJpRK56tNs0iOr87fp9magmjJcKNmmC+rKond1VUhdMoknawkf6N7O691TPmk+b0MfFn3JGjpDaUOKyQZENVnD+YLBijvPvswIDAQAB-----END PUBLIC KEY-----'

//chart data
export const barChart = [
  {
    id: 1,
    months: 'Jan',
    userGain: 80000,
    userLost: 823
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345
  },
  {
    id: 3,
    months: 'Feb',
    userGain: 78888,
    userLost: 555
  },
  {
    id: 4,
    months: 'Mar',
    userGain: 90000,
    userLost: 4555
  },
  {
    id: 5,
    months: 'Apr',
    userGain: 43000,
    userLost: 2234
  },
  {
    id: 6,
    months: 'May',
    userGain: 78000,
    userLost: 2384
  },
  {
    id: 7,
    months: 'Jun',
    userGain: 77300,
    userLost: 2934
  }
]

// Constant data array for the redFlags
export const redFlags = [
  {
    reference:
      'ASOS Ridley The issuer infringes Article 38(3) by not holding in custody in accordance with Article 37 the financial instruments in which the reserve of assets is invested.',
    documentId: '32014D0753'
  },
  {
    reference:
      'Solidato must ensure that all financial instruments are properly held in custody as per regulatory requirements. Failure to do so could result in loss of assets and non-compliance penalties.',
    documentId: '32014D0754'
  },
  {
    reference:
      'The issuer infringes Article 38(3) by not holding in custody in accordance with Article 37 the financial instruments in which the reserve of assets is invested.',
    documentId: '32014D0773'
  },
  {
    reference: 'Reference: The issuer infringes Article 45(5) by not complying, at all times, with the own funds requirement.',
    documentId: '32014D0953'
  }
]

// Constant data array for the news
export const latestNews = [
  {
    title: 'New GDPR Update: Stricter Data Protection Measures Implemented',
    source: 'Yahoo',
    segment: 'GDPR',
    segmentClass: 'text-regular-100'
  },
  {
    title: 'Financial Reporting Standards Overhauled to Increase Transparency',
    source: 'BaFin',
    segment: 'GDPR',
    segmentClass: 'text-regular-200'
  },
  {
    title: 'New Labor Laws Passed to Ensure Fair Wages and Equal Treatment',
    source: 'EBA',
    segment: 'Safety',
    segmentClass: 'text-regular-300'
  },
  {
    title: 'New IFRS Standards Introduced to Enhance Financial Transparency',
    source: 'Yahoo',
    segment: 'Taxation',
    segmentClass: 'text-regular-400'
  },
  {
    title: 'SEC Enhances Reporting Obligations for Market Participants - Reporting',
    source: 'EPRA',
    segment: 'Reporting',
    segmentClass: 'text-primary-200'
  }
]

//JurisdictionPresenceChartData
export const JurisdictionPresenceChartData = [
  { city: 'New York', clients: '20K', progress: '80' },
  { city: 'San Francisco', clients: '20K', progress: '20' },
  { city: 'Sydney', clients: '632', progress: '70' },
  { city: 'Singapore', clients: '7K', progress: '10' }
]

//password verify


