import React, { useState, useEffect } from 'react';
import { Calendar, AlertTriangle, TrendingUp, FileText, Bell } from 'react-feather';
import { Bar, Pie, Line } from 'react-chartjs-2';
import BarChartComponent from '../../shared/ui/BarChart';
import { useCompany } from '../../contexts/CompanyContext';
import { useCompanies } from '../../Query/Dashboard/companyQuery';
import { useNavigate } from 'react-router-dom';
import { useFetchMicroDashboardData } from '../../Query/Dashboard/microDashboardQuery';
import { useQuery } from '@tanstack/react-query';
import apiClient from '../../lib';

// Backend API endpoints (TO BE IMPLEMENTED)
// GET /api/compliance/stats - Key compliance metrics and regulatory status
// GET /api/compliance/news - Latest regulatory news and compliance updates
// GET /api/compliance/calendar - Important compliance deadlines and audits
// GET /api/compliance/ai-summary - AI-generated compliance analysis and risk assessment

function DashboardOverview() {
  const navigate = useNavigate();
  const { companies, selectedCompany } = useCompany();
  const { data: microDashboardData } = useFetchMicroDashboardData();
  const { data: complianceAnalysisData } = useQuery({
    queryKey: ['complianceAnalysis', selectedCompany],
    queryFn: async () => {
      const response = await apiClient.get(`dashboard/compliance-analysis`);
      return response.data;
    },
  });

  const { data: companiesData } = useCompanies();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    companyStats: {
      complianceRate: "0%", // Default mock value
      activeRegulations: "156",
      pendingActions: "0",
      riskLevel: "Low",
    },
    latestNews: [],
    upcomingDeadlines: [],
    aiSummary: ''
  });

  // Update data when compliance analysis data changes
  useEffect(() => {
    if (complianceAnalysisData) {
      const score = complianceAnalysisData.oData.average_compliance_score;
      setData(prevData => ({
        ...prevData,
        companyStats: {
          ...prevData.companyStats,
          complianceRate: score === 0 ? "0%" : `${score}%` // Use mock value if 0
        }
      }));
    }
  }, [complianceAnalysisData]);

  useEffect(() => {
    if (microDashboardData?.nScannedDocument) {
      console.log('Micro Dashboard Data:', microDashboardData)
      const totalRedFlags = Object.values(microDashboardData.nScannedDocument || {})
        .reduce((sum, value) => sum + value, 0);

      const highRiskCount = complianceAnalysisData?.oData?.risk_distribution?.HIGH || 0;
      const totalCount = totalRedFlags + highRiskCount;

      setData(prevData => ({
        ...prevData,
        companyStats: {
          ...prevData.companyStats,
          pendingActions: totalCount.toString()
        }
      }));
    }
  }, [microDashboardData, complianceAnalysisData]);

  // Redirect to overview on initial load
  useEffect(() => {
    if (window.location.pathname === '/dashboard') {
      navigate('/dashboard/overview');
    }
  }, [navigate]);

  // Update data based on companies
  useEffect(() => {
    if (companiesData) {
      setData(prevData => ({
        ...prevData,
        companyStats: {
          ...prevData.companyStats,
          activeRegulations: companiesData.length.toString()
        }
      }));
    }
  }, [companiesData]);

  // Add this effect to update red flags and pending reviews
  useEffect(() => {
    if (companiesData?.oReport) {
      const totalRedFlags = companiesData.oReport.length;
      const pendingReviews = companiesData.oReport.filter(report => 
        report.status === 'pending' || report.status === 'Pending'
      ).length;

      setData(prevData => ({
        ...prevData,
        companyStats: {
          ...prevData.companyStats,
          pendingActions: totalRedFlags.toString()
        }
      }));
    }
  }, [companiesData]);

  // Simulate API fetch
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
      } catch (err) {
        setError('Failed to load dashboard data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  // Add click handlers
  const handleNewsClick = (newsItem) => {
    console.log('News clicked:', newsItem);
    // Add navigation or modal logic here
  };

  const handleDeadlineClick = (deadline) => {
    console.log('Deadline clicked:', deadline);
    // Add navigation or modal logic here
  };

  // Replace the mock data with real data from the API, falling back to mock values when needed
  const overviewData = {
    complianceScore: {
      labels: complianceAnalysisData?.oData.company_scores && 
             Object.keys(complianceAnalysisData.oData.company_scores).length > 0 ? 
             Object.keys(complianceAnalysisData.oData.company_scores) :
             ['Company A', 'Company B', 'Company C', 'Company D'], // Mock values
      datasets: [{
        label: 'Compliance Score',
        data: complianceAnalysisData?.oData.company_scores &&
              Object.values(complianceAnalysisData.oData.company_scores).length > 0 ?
              Object.values(complianceAnalysisData.oData.company_scores) :
              [0, 0, 0, 0], // Mock values
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
      }]
    },
    riskDistribution: {
      labels: ['High Risk', 'Medium Risk', 'Low Risk'],
      datasets: [{
        data: complianceAnalysisData?.oData.risk_distribution ? 
          [
            complianceAnalysisData.oData.risk_distribution.HIGH || 0,
            complianceAnalysisData.oData.risk_distribution.MEDIUM || 0,
            complianceAnalysisData.oData.risk_distribution.LOW || 0
          ].some(value => value > 0) ? // Check if any value is greater than 0
          [
            complianceAnalysisData.oData.risk_distribution.HIGH,
            complianceAnalysisData.oData.risk_distribution.MEDIUM,
            complianceAnalysisData.oData.risk_distribution.LOW,
          ] :
          [0, 0, 0] // Mock values if all are 0
          : [0, 0, 0], // Mock values if no data
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
        ],
      }]
    },
    regulatoryTrends: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      datasets: [{
        label: 'New Regulations',
        data: [0, 0, 0, 0, 0, 0],
        borderColor: 'rgba(54, 162, 235, 1)',
      }]
    }
  };

  // Loading state
  if (loading) {
    return (
      <div className="p-6 flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading dashboard...</p>
        </div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
          <div className="flex items-center">
            <AlertTriangle className="text-red-400 mr-2" size={20} />
            <p className="text-red-700">{error}</p>
          </div>
          <button 
            onClick={() => window.location.reload()} 
            className="mt-2 text-red-600 hover:text-red-800 underline"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      {/* Stats Overview */}
      <div className="grid grid-cols-4 gap-4 mb-6">
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-sm font-semibold text-gray-600">Total Companies</h3>
          <p className="text-2xl font-bold mt-2">{companiesData?.length || 0}</p>
        </div>
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-sm font-semibold text-gray-600">Average Compliance Score</h3>
          <p className="text-2xl font-bold mt-2">{data.companyStats.complianceRate}</p>
        </div>
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-sm font-semibold text-gray-600">Total Red Flags</h3>
          <p className="text-2xl font-bold mt-2">{data.companyStats.pendingActions}</p>
        </div>
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-sm font-semibold text-gray-600">Pending Reviews</h3>
          <p className="text-2xl font-bold mt-2">
            {companiesData?.oReport?.filter(report => 
              report.status === 'pending' || report.status === 'Pending'
            ).length || 0}
          </p>
        </div>
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-2 gap-6 mb-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">Compliance Scores by Company</h3>
          <div className="h-[300px]">
            <Bar data={overviewData.complianceScore} options={{ maintainAspectRatio: false }} />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">Risk Distribution</h3>
          <div className="h-[300px]">
            <Pie data={overviewData.riskDistribution} options={{ maintainAspectRatio: false }} />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6 mb-6">
        <h3 className="text-lg font-semibold mb-4">Regulatory Compliance Trends</h3>
        <div className="h-[300px]">
          <Line data={overviewData.regulatoryTrends} options={{ maintainAspectRatio: false }} />
        </div>
      </div>

      {/* Latest Updates Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-bold mb-4">Latest Updates</h2>
            <div className="space-y-4">
              {data.latestNews.map((news) => (
                <div 
                  key={news.id} 
                  className="border-b pb-4 cursor-pointer hover:bg-gray-50 transition-colors rounded p-2"
                  onClick={() => handleNewsClick(news)}
                  role="button"
                  tabIndex={0}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-semibold">{news.title}</h3>
                      <p className="text-sm text-gray-600 mt-1">{news.summary}</p>
                      <p className="text-sm text-gray-500 mt-2">{news.date}</p>
                    </div>
                    <span className={`px-2 py-1 rounded text-xs ${
                      news.priority === 'High' ? 'bg-red-100 text-red-800' : 
                      'bg-yellow-100 text-yellow-800'
                    }`}>
                      {news.priority}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Upcoming Deadlines */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-bold mb-4">Upcoming Deadlines</h2>
          <div className="space-y-4">
            {data.upcomingDeadlines.map((deadline) => (
              <div 
                key={deadline.id} 
                className="flex items-center space-x-4 border-b pb-4 cursor-pointer hover:bg-gray-50 transition-colors rounded p-2"
                onClick={() => handleDeadlineClick(deadline)}
                role="button"
                tabIndex={0}
              >
                <Calendar className="text-gray-400" size={20} />
                <div className="flex-1">
                  <h3 className="font-semibold">{deadline.title}</h3>
                  <p className="text-sm text-gray-600 mt-1">{deadline.description}</p>
                  <p className="text-sm text-gray-500">Due: {deadline.dueDate}</p>
                  <span className={`inline-block px-2 py-1 rounded text-xs mt-1 ${
                    deadline.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' : 
                    'bg-blue-100 text-blue-800'
                  }`}>
                    {deadline.status}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardOverview;
