import React, { useState } from 'react'
import { FiChevronLeft, FiChevronRight, FiPlus, FiX, FiClock, FiTag, FiAlertCircle } from 'react-icons/fi'
import { IoDocumentAttachOutline } from 'react-icons/io5'

/**
 * Calendar Component for Regulatory Events
 * 
 * Backend Integration Notes:
 * 1. API Endpoints Needed:
 *    - GET /api/regulatory-events?month={month}&year={year}
 *    - POST /api/regulatory-events (for creating new events)
 *    - GET /api/regulatory-events/{id} (for fetching single event details)
 * 
 * 2. Expected Event Object Structure:
 *    {
 *      id: string|number,
 *      date: ISO8601 string,
 *      title: string,
 *      priority: 'high'|'medium'|'low',
 *      description: string,
 *      category: string,
 *      time: string (HH:mm format),
 *      highlights: string[],
 *      impact: string,
 *      relatedDocs: Array<{ id: string, name: string, url: string }>
 *    }
 */
function Calendar() {
  // State Management
  const [view, setView] = useState('month')
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [currentDate, setCurrentDate] = useState(new Date()) // TODO: Add month navigation
  
  // Mock data for development - Replace with API call
  const events = [
    {
      id: 1,
      date: '2024-03-20',
      title: 'SEC Filing Deadline',
      priority: 'high',
      description: 'Quarterly SEC filing deadline for Q4 2023',
      category: 'SEC Filing',
      time: '14:00',
      highlights: [
        'Form 10-Q submission required',
        'Financial statements must be reviewed by external auditors',
        'CEO and CFO certifications needed'
      ],
      impact: 'Critical compliance requirement with potential penalties for late submission',
      relatedDocs: [
        'Previous Q3 2023 Filing',
        'SEC Guidelines 2024',
        'Internal Control Documentation'
      ]
    },
    {
      id: 2,
      date: '2024-03-15',
      title: 'New ESG Regulation Review',
      priority: 'medium',
      description: 'Review of upcoming ESG disclosure requirements',
      category: 'ESG',
      time: '10:30',
      highlights: [
        'New carbon emissions reporting format',
        'Supply chain sustainability assessment',
        'Social impact metrics update'
      ],
      impact: 'Enhanced disclosure requirements affecting annual reporting',
      relatedDocs: [
        'ESG Framework 2024',
        'Industry Benchmarks',
        'Sustainability Report Template'
      ]
    },
    {
      id: 3,
      date: '2024-03-25',
      title: 'Compliance Training Due',
      priority: 'low',
      description: 'Annual compliance training deadline',
      category: 'Training',
      time: '09:00'
    }
  ]

  /**
   * TODO: Replace with API call
   * Expected API call: fetchEvents(year, month)
   * Should return array of events matching the structure above
   */
  const fetchEvents = async (year, month) => {
    // Placeholder for API integration
    return events
  }

  /**
   * TODO: Replace with API call
   * Expected API call: createEvent(eventData)
   */
  const handleAddEvent = async (eventData) => {
    // Placeholder for API integration
    console.log('Create event:', eventData)
  }

  // Utility Functions
  const getPriorityColor = (priority) => {
    const priorityColors = {
      high: 'bg-red-100 border-red-400 text-red-700',
      medium: 'bg-orange-100 border-orange-400 text-orange-700',
      low: 'bg-yellow-100 border-yellow-400 text-yellow-700'
    }
    return priorityColors[priority] || 'bg-gray-100'
  }

  // Calendar Generation
  const daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1) // TODO: Calculate actual days based on current month
  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  // Update the event card click handler
  const handleEventClick = (event) => {
    setSelectedEvent(event)
  }

  return (
    <div className="p-8 bg-gray-50 min-h-screen relative">
      {/* Coming Soon Overlay */}
      <div className="absolute inset-0 bg-white/60 backdrop-blur-sm z-10">
        <div className="text-center pt-8">
          <h2 className="text-4xl font-bold text-gray-800 mb-2">Coming Soon</h2>
          <p className="text-gray-600">This feature is currently under development</p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="mb-8 flex justify-between items-center">
          <div>
            <h2 className="text-3xl font-bold text-gray-900">Regulatory Calendar</h2>
            <p className="text-gray-500 mt-1">Keep track of important regulatory deadlines and events</p>
          </div>
          <button className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors">
            <FiPlus className="w-5 h-5" />
            Add Event
          </button>
        </div>

        {/* View Controls */}
        <div className="mb-6 flex justify-between items-center">
          <div className="flex gap-2">
            {['month', 'week', 'day'].map((viewType) => (
              <button
                key={viewType}
                onClick={() => setView(viewType)}
                className={`px-4 py-2 rounded-lg font-medium transition-colors ${
                  view === viewType
                    ? 'bg-blue-600 text-white'
                    : 'bg-white text-gray-600 hover:bg-gray-100'
                }`}
              >
                {viewType.charAt(0).toUpperCase() + viewType.slice(1)}
              </button>
            ))}
          </div>
          <div className="flex items-center gap-4">
            <button className="p-2 hover:bg-gray-100 rounded-full transition-colors">
              <FiChevronLeft className="w-5 h-5" />
            </button>
            <h3 className="text-xl font-semibold text-gray-900">March 2024</h3>
            <button className="p-2 hover:bg-gray-100 rounded-full transition-colors">
              <FiChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Calendar */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200">
          {/* Week days header */}
          <div className="grid grid-cols-7 border-b border-gray-200">
            {weekDays.map(day => (
              <div key={day} className="py-3 text-center text-sm font-semibold text-gray-600">
                {day.slice(0, 3)}
              </div>
            ))}
          </div>

          {/* Calendar grid */}
          <div className="grid grid-cols-7">
            {daysInMonth.map(day => (
              <div
                key={day}
                className="min-h-[120px] p-2 border-b border-r border-gray-200 hover:bg-gray-50 transition-colors"
              >
                <div className="font-medium text-sm text-gray-900 mb-1">{day}</div>
                <div className="space-y-1">
                  {events
                    .filter(event => new Date(event.date).getDate() === day)
                    .map(event => (
                      <div
                        key={event.id}
                        onClick={() => handleEventClick(event)}
                        className={`p-2 rounded-lg border ${getPriorityColor(
                          event.priority
                        )} cursor-pointer hover:shadow-sm transition-shadow`}
                      >
                        <div className="text-xs font-medium">{event.time}</div>
                        <div className="text-sm font-semibold truncate">{event.title}</div>
                        <div className="text-xs opacity-75">{event.category}</div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Event Modal */}
        {selectedEvent && (
          <EventModal 
            event={selectedEvent} 
            onClose={() => setSelectedEvent(null)} 
            getPriorityColor={getPriorityColor}
          />
        )}

        {/* Legend */}
        <div className="mt-6 flex gap-6 items-center justify-end">
          <div className="flex items-center gap-6">
            <div className="flex items-center">
              <div className="w-3 h-3 bg-red-100 border border-red-400 rounded-full mr-2"></div>
              <span className="text-sm text-gray-600">High Priority</span>
            </div>
            <div className="flex items-center">
              <div className="w-3 h-3 bg-orange-100 border border-orange-400 rounded-full mr-2"></div>
              <span className="text-sm text-gray-600">Medium Priority</span>
            </div>
            <div className="flex items-center">
              <div className="w-3 h-3 bg-yellow-100 border border-yellow-400 rounded-full mr-2"></div>
              <span className="text-sm text-gray-600">Low Priority</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Event Modal Component
 * TODO: Consider moving to separate file for better maintainability
 */
const EventModal = ({ event, onClose, getPriorityColor }) => {
  if (!event) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
        {/* Modal Header */}
        <div className="border-b border-gray-200 p-4 flex justify-between items-start">
          <div>
            <h3 className="text-xl font-bold text-gray-900">{event.title}</h3>
            <div className="flex items-center gap-3 mt-2 text-sm text-gray-600">
              <div className="flex items-center gap-1">
                <FiClock className="w-4 h-4" />
                {event.time}
              </div>
              <div className="flex items-center gap-1">
                <FiTag className="w-4 h-4" />
                {event.category}
              </div>
              <div className={`flex items-center gap-1 px-2 py-1 rounded-full text-xs ${
                getPriorityColor(event.priority)
              }`}>
                <FiAlertCircle className="w-3 h-3" />
                {event.priority.charAt(0).toUpperCase() + event.priority.slice(1)} Priority
              </div>
            </div>
          </div>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <FiX className="w-5 h-5" />
          </button>
        </div>

        {/* Modal Content */}
        <div className="p-6 space-y-6">
          {/* Description */}
          <div>
            <h4 className="font-semibold text-gray-900 mb-2">Description</h4>
            <p className="text-gray-600">{event.description}</p>
          </div>

          {/* Key Highlights */}
          <div>
            <h4 className="font-semibold text-gray-900 mb-2">Key Highlights</h4>
            <ul className="list-disc list-inside space-y-1 text-gray-600">
              {event.highlights.map((highlight, index) => (
                <li key={index}>{highlight}</li>
              ))}
            </ul>
          </div>

          {/* Business Impact */}
          <div>
            <h4 className="font-semibold text-gray-900 mb-2">Business Impact</h4>
            <p className="text-gray-600">{event.impact}</p>
          </div>

          {/* Related Documents */}
          <div>
            <h4 className="font-semibold text-gray-900 mb-2">Related Documents</h4>
            <div className="space-y-2">
              {event.relatedDocs.map((doc, index) => (
                <div 
                  key={index}
                  className="flex items-center gap-2 p-2 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer"
                >
                  <IoDocumentAttachOutline className="w-4 h-4 text-gray-600" />
                  <span className="text-sm text-gray-600">{doc}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Modal Footer */}
        <div className="border-t border-gray-200 p-4 flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Close
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
            Add to Calendar
          </button>
        </div>
      </div>
    </div>
  )
}

export default Calendar